body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f5f9;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bgImage {
  background: url("/public/bg-image.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.bg-AppBar-default {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-style: solid;
  border-color: #e7ebf0;
  border-width: 0;
  border-bottom-width: thin;
  background: #2962ff;
  background-color: #2962ff !important;
  color: #2d3843;
}

.bg-AppBar {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-style: solid;
  border-color: #e7ebf0;
  border-width: 0;
  border-bottom-width: thin;
  background: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #2d3843;
}

.bg-AppBarDark {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-style: solid;
  border-color: rgba(194, 224, 255, 0.08);
  border-width: 0;
  border-bottom-width: thin;
  background: rgba(10, 25, 41, 0.7);
  background-color: rgba(10, 25, 41, 0.7) !important;
  color: rgb(160, 170, 180);
}

.bgLanding {
  background-image: url("./image/wallet-interest.png");
  background-color: transparent;
  background-size: 45%;
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 70vh;
}

.tableResponsive {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

.tabsCs {
  overflow: hidden;
  min-height: 48px;
  margin-bottom: 10px;
  position: sticky;
  top: 55px;
  z-index: 1;
  border-top: 1px solid rgb(231, 235, 240);
  border-right-color: rgb(231, 235, 240);
  border-bottom-color: rgb(231, 235, 240);
  border-left-color: rgb(231, 235, 240);
}

.cssRecomended {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.7;
  letter-spacing: 0px;
  font-weight: 700;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  color: rgb(0, 127, 255);
  padding: 2px 8px;
  border: 1px solid rgb(194, 224, 255);
  border-radius: 20px;
  position: absolute;
  margin-top: 10px;
  margin-left: 40px;
  transform: translateY(-50%);
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #027fff !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #027fff !important;
}
.carousel.carousel-slider .control-arrow {
  color: #fff !important;
  padding: 15px !important;
}

.chakra-link {
  display: none !important;
}

.cssCarousel {
  position: absolute;
  height: 100%;
  padding: 0 25px;
}

.lodVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 10%;
  min-height: 10%;
}

.cssBackgroundCardColor {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  color: rgb(255, 255, 255) !important;
  border-radius: 16px;
  padding: 9px;
  box-shadow: none;
  background: linear-gradient(
    135deg,
    rgb(67, 203, 255) 0%,
    rgb(151, 8, 204) 100%
  );
}

@media only screen and (max-width: 899px) {
  .bgLanding {
    background-image: none;
    min-height: auto;
    background-size: 59%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS PHONE NUMBER */
.bg-balance {
  background-color: #1f2d3b;
  padding: 4px;
  border-radius: 4px;
  margin-right: 8px;
}